import React from 'react';

const Card = ({ fill }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0002 16.6667C9.54183 16.6667 9.14947 16.5035 8.82308 16.1771C8.49669 15.8507 8.3335 15.4584 8.3335 15C8.3335 14.5417 8.49669 14.1493 8.82308 13.823C9.14947 13.4966 9.54183 13.3334 10.0002 13.3334C10.4585 13.3334 10.8509 13.4966 11.1772 13.823C11.5036 14.1493 11.6668 14.5417 11.6668 15C11.6668 15.4584 11.5036 15.8507 11.1772 16.1771C10.8509 16.5035 10.4585 16.6667 10.0002 16.6667ZM10.0002 11.6667C9.54183 11.6667 9.14947 11.5035 8.82308 11.1771C8.49669 10.8507 8.3335 10.4584 8.3335 10C8.3335 9.54171 8.49669 9.14935 8.82308 8.82296C9.14947 8.49657 9.54183 8.33337 10.0002 8.33337C10.4585 8.33337 10.8509 8.49657 11.1772 8.82296C11.5036 9.14935 11.6668 9.54171 11.6668 10C11.6668 10.4584 11.5036 10.8507 11.1772 11.1771C10.8509 11.5035 10.4585 11.6667 10.0002 11.6667ZM10.0002 6.66671C9.54183 6.66671 9.14947 6.50351 8.82308 6.17712C8.49669 5.85073 8.3335 5.45837 8.3335 5.00004C8.3335 4.54171 8.49669 4.14935 8.82308 3.82296C9.14947 3.49657 9.54183 3.33337 10.0002 3.33337C10.4585 3.33337 10.8509 3.49657 11.1772 3.82296C11.5036 4.14935 11.6668 4.54171 11.6668 5.00004C11.6668 5.45837 11.5036 5.85073 11.1772 6.17712C10.8509 6.50351 10.4585 6.66671 10.0002 6.66671Z" fill={fill} />
        </svg>
    );
};

export default Card; 
