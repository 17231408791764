import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import style from '../Style/logout.module.css';
import { logOutHandlerForMobile, errorHandlerForMobile } from '../../utills/validator';

const Logout = () => {
  useEffect(() => {
    (async () => {
      try {
        console.log('code', localStorage.getItem('code'));
        if (localStorage.getItem('code') && localStorage.getItem('code').length > 10) {
          await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_URL}/sso/logout/all-sessions`,
            headers: { Authorization: `Bearer ${localStorage.getItem('code')}` },
          });
        }
        try {
          // Clear localStorage
          localStorage.clear();
          // Clear all cookies
          document.cookie.split(';').forEach((cookie) => {
            const name = cookie.split('=')[0].trim();
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          });
        } catch (error) {
          console.log(error);
        }
        const queryParams = new URLSearchParams(window.location.search);
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        const redirectUrl = queryParams.get('redirectUrl');
        if (!redirectUrl.includes('conscent-app-sdk')) {
          window.location.href = `${redirectUrl}`;
        }
      } catch (error) {
        try {
          // Clear localStorage
          localStorage.clear();
          // Clear all cookies
          document.cookie.split(';').forEach((cookie) => {
            const name = cookie.split('=')[0].trim();
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          });
        } catch (error) {
          console.log(error);
        }
        const queryParams = new URLSearchParams(window.location.search);
        const value = JSON.stringify({ message: 'LOGOUT_FAILED', errorCode: '400' });
        errorHandlerForMobile(value);
        const redirectUrl = queryParams.get('redirectUrl');
        if (!redirectUrl.includes('conscent-app-sdk')) {
          window.location.href = `${redirectUrl}`;
        }
      }
    })();
  }, []);
  return (
    <div className={style['loader-container']}>
      <div className={style['loader']}></div>
      <div className={style['message']}>Logging Out</div>
      <div className={style['sub-message']}>Do not close this window</div>
    </div>
  );
};
export default Logout;
