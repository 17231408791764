import React, { useState, useEffect } from 'react';
import style from '../Style/Delete.module.css';
import Arrow from './icons/back Vector';
import Delete from './icons/delete';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import { userProfileHandlerForMobile } from './utills';
import Button from './button';
import axios from 'axios';
import { Spin } from 'antd';
import { checkSuccessCode, logOutHandlerForMobile } from '../../utills/validator';
import { antdMessageError, antdMessageSuccess } from '../../Components/common/antdMessageComponent';

const DeleteAccount = ({ setscreen, rightvaluepassed, color, logout }) => {
  const [data, setdata] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const [inputvalue, setinputvalue] = useState('');
  const [blur, setblur] = useState(true);
  const del = 'DELETE';
  const textColor = '#00063d';
  const backgroundColor = '#f5f5ff';
  const highlightColor = '#7583ff';
  const inputfieldColor = '#e0e4ff';
  const deleteColor = '#ff6666';
  const fontstyle = 'poppins';

  useEffect(() => {
    if (del === inputvalue) {
      setblur(false);
    } else {
      setblur(true);
    }
  }, [inputvalue]);

  const [rightvalue, setrightvalue] = useState(null);

  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);

  const fetchData = async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${token}` },
      });
      setdata(response.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError('It seems your account has been logged out. Please try logging in again');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    }
  };
  useEffect(() => {
    const refreshToken = queryParams.get('refreshToken');

    refreshToken &&
      (async () => {
        const accessToken = localStorage.getItem('accessToken');
        CSCCheckJWTExpired(accessToken);
        if (accessToken.length > 10 && !CSCCheckJWTExpired(accessToken)) {
          fetchData(accessToken);
        } else {
          const accessToken = await CSCGetAccessToken(refreshToken);
          localStorage.setItem('accessToken', accessToken);
          fetchData(accessToken);
        }
      })();
  }, []);

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue(null);
    }
  }, [data]);

  const deleteUser = async () => {
    if (localStorage.getItem('accessToken') !== null) {
      try {
        const response = await axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_API_URL}/authorization/delete-user-account`,
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        });
        if (!checkSuccessCode(response)) return;
        const value = JSON.stringify({ message: 'USER_DELETE_ACCOUNT_SUCCESS', statusCode: '200' });
        userProfileHandlerForMobile(value);
        try {
          // Clear localStorage
          localStorage.clear();
          // Clear all cookies
          document.cookie.split(';').forEach((cookie) => {
            const name = cookie.split('=')[0].trim();
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          });
        } catch (error) {
          console.log(error);
        }
        antdMessageSuccess('Successfully Deleted');
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        let mobileView = queryParams.get('mobileView');
        let clientId = queryParams.get('clientId');

        // let logoutUrl = queryParams.get('logoutUrl');
        if (mobileView) {
          window.location.href =
            window.location.origin + `/logout?redirectUrl=https://conscent-app-sdk&clientId=${clientId}`;
        }
      } catch (error) {
        console.error('Error Deleting user profile :', error);
        let value = JSON.stringify({ message: 'USER_DELETE_ACCOUNT_FAILED', statusCode: '400' });
        userProfileHandlerForMobile(value);

        antdMessageError('Error While Deleting User Account');
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  const collor = data?.userProfileCustomization?.highlightColor || highlightColor;
  const divStyle = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused ? '0 0 0' : '0 0 2px',
  };

  const none = () => {};

  return (
    <>
      {loading ? (
        <div
          className={style['outercontainer']}
          style={{
            background: color,
            right: rightvaluepassed,
          }}
        >
          <div className={style['loader-container']}>
            <div className={style['loader']}></div>
          </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvalue,
            background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
            fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
            color: data?.userProfileCustomization?.primaryTextColor || textColor,
          }}
        >
          <div className={style['editable']}>
            <div
              className={style['head']}
              style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
            >
              <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
                <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
              </div>
              <div className={style['topic']}>Delete Account</div>
            </div>
          </div>

          <div className={style['content']}>
            <div className={style['deleteimage']}>
              <Delete fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
            </div>
            <div className={style['confirmationBox']}>
                <h3>Are you sure you want to delete your account?</h3>
                <p>
                  Once deleted, this action cannot be undone, and all your data will be permanently erased. If you have
                  an active subscription, you will lose access immediately, and refunds are not available.
                </p>
              </div>
          </div>
          <span className={style['confirmtext']}>
            To Confirm, type <b>"DELETE"</b>
          </span>
          <div className={style['inputdiv']} style={divStyle}>
            <input
              style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
              type="text"
              maxLength="6"
              className={style['change']}
              onChange={(e) => {
                setinputvalue(e.target.value);
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={inputvalue}
            ></input>
          </div>

          <div className={`${style['btndiv']} ${blur ? style['blur'] : ''}`}>
            <Button
              color={data?.userProfileCustomization?.accountDeletionColor || deleteColor}
              font={data?.userProfileCustomization?.fontStyle || fontstyle}
              fnc={!blur ? deleteUser : none}
              text="Delete Account"
              textcolor={data?.userProfileCustomization?.accountDeletionTextColor || textColor}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteAccount;
