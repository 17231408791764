import React, { useEffect, useState } from 'react';
import style from '../Style/Edituser.module.css';
import Arrow from './icons/back Vector';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import Button from './button';
import axios from 'axios';
import { antdMessageError, antdMessageSuccess } from '../../Components/common/antdMessageComponent';
import { checkSuccessCode, logOutHandlerForMobile, userProfileUpdateHandlerForMobile } from '../../utills/validator';

const EditCard = ({ setscreen, Data, rightvaluepassed, color }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(true);
    const [data, setdata] = useState(Data);
    const [rightvalue, setrightvalue] = useState();
    const textColor = 'red';
    const backgroundColor = '#f5f5ff';
    const highlightColor = data?.userProfileCustomization?.highlightColor || '#7583ff';
    const inputfieldColor = data?.userProfileCustomization?.highlightColor || '#e0e4ff';
    const fontstyle = 'poppins';
    const clientConfig = JSON.parse(localStorage.getItem('commonUrlData'));
    const [name, setName] = useState('')
    const [cardNumber, setCardNumber] = useState();
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [cardType, setCardType] = useState('');
    const [click,setclick] = useState(true);
    const [adding,setAdding] = useState(false);
    const [validExpiry,setValidExpiry] = useState(true)
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const detectCardType = (number) => {
        const cleanedNumber = number.replace(/\D/g, "");

        if (/^4/.test(cleanedNumber)) {
            return "001"; // VS (Visa)
        } else if (/^5[1-5]/.test(cleanedNumber)) {
            return "002"; // MS (MasterCard)
        } else if (/^3[47]/.test(cleanedNumber)) {
            return "003"; // AMEX (American Express)
        } else if (/^6(?:011|5)/.test(cleanedNumber)) {
            return "004"; // DS (Discover)
        } else if (/^35/.test(cleanedNumber)) {
            return "007"; // JCB
        } else if (/^3(?:0[0-5]|[68])/.test(cleanedNumber)) {
            return "005"; // DINER (Diners Club)
        } else {
            return "Unknown";
        }
    };

    const handleCardNumberChange = (e) => {
        const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
        const formattedNumber = onlyNumbers.replace(/(.{4})/g, "$1 ").trim(); // Format as 'XXXX XXXX XXXX XXXX'

        setCardNumber(formattedNumber); // Update card number
        setCardType(detectCardType(formattedNumber))
        // setCardType(detectCardType(onlyNumbers)); // Detect and update card type
    };

    const handleExpiryDateChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^\d/]/g, "");
        if (input.length === 2 && !input.includes("/")) {
            input = input + "/";
        }
        if (input.length > 5) {
            input = input.slice(0, 5);
        }
        setExpiry(input)


    };
    const fetchData = async (token) => {
        if (token) {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
                    headers: { Authorization: `Bearer ${token}` },
                });
                setdata(response.data);
                setLoading(false);
            } catch (error) {
                if (error?.response?.status === 404) {
                    antdMessageError('It seems your account has been logged out. Please try logging in again');
                    window.parent.postMessage({ source: 'logout-from-user' }, '*');
                    logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
                }
            }
        }
    };
    const addCard = async () => {
        setAdding(true);
        setLoading(true);
        const accessToken = localStorage.getItem('accessToken');
        const monthAndYear = expiry?.split("/")
        if (accessToken) {
            try {
                const response = await axios({
                    method: 'PATCH',
                    url: `${process.env.REACT_APP_API_URL}/cybersource/add-card`,
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: {
                        clientId : clientConfig?.clientId,
                        nameOnCard: name,
                        cardExpiryMonth : monthAndYear?.[0],
                        cardExpiryYear:`20${monthAndYear?.[1]}`,
                        cardNumber:cardNumber?.replaceAll(/\s/g, ""),
                        cardType:cardType,
                        securityCode:cvv,
                    }
                });
                antdMessageSuccess('Card details added successfully.')
                setLoading(false);
                setscreen('userdetails');
            } catch (error) {
                setLoading(false);
                if (error?.response?.status !== 201) {
                    antdMessageError('Cannot complete the action.');
                }
            }
        }
    }

    useEffect(()=>{
        if(name?.trim()?.length > 1 && cardNumber?.length >= 16 && expiry?.length === 5 && cvv?.length >= 3 ){
            setclick(false)
        }else{
            setclick(true)
        }
    },[name,cardNumber,expiry,cvv])

    useEffect(() => {
        const refreshToken = queryParams.get('refreshToken');
        refreshToken &&
            (async () => {
                const accessToken = localStorage.getItem('accessToken');
                CSCCheckJWTExpired(accessToken);
                if (accessToken.length > 10 && !CSCCheckJWTExpired(accessToken)) {
                    fetchData(accessToken);
                } else {
                    const accessToken = await CSCGetAccessToken(refreshToken);
                    localStorage.setItem('accessToken', accessToken);
                    fetchData(accessToken);
                }
            })();
    }, []);

    useEffect(() => {
        if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
            setrightvalue(0);
        } else {
            setrightvalue();
        }
    }, [data, rightvalue]);
    useEffect(()=>{
        const monthAndYear = expiry?.split("/");
        const enteredMonth = monthAndYear[0];
        const enteredYear = `20${monthAndYear[1]}`;
        if(enteredMonth >= currentMonth && enteredYear >= currentYear){
            setValidExpiry(false)
        }else{
            setValidExpiry(true)
        }
    },[expiry])
    const none = () => {};
    
    return (
        <>
            {loading ? (
                <div
                    className={style['outercontainer']}
                    style={{
                        background: color,
                        right: rightvaluepassed,
                    }}
                >
                    <div className={style['loader-container']}>
                        <div className={style['loader']}></div>
                       {adding && <div style={{padding:'0 15px'}}>Processing your card details, please hold on for a moment.</div>}
                    </div>
                </div>
            ) : (
                <div
                    className={style['outercontainer']}
                    style={{
                        right: rightvalue,
                        background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
                        fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
                        color: data?.userProfileCustomization?.primaryTextColor || textColor,
                    }}
                >
                    <div className={style['editable']}>
                        <div
                            className={style['head']}
                            style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
                        >
                            <div className={style['arrow']} onClick={() => setscreen('cards')}>
                                <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                            </div>
                            <div className={style['topic']}>Add New Card</div>
                        </div>

                        <div className={style['addCardInput']} >
                            <div className={style['inputbtns']} style={{ border: `1px solid ${highlightColor}` }}>
                                <input
                                    value={name}
                                    placeholder="Name on Card"
                                    style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor, height: '100%', width: '100%', border: 'none', background: 'transparent', fontSize: '14px' }}
                                    onChange={(e) => {
                                        const reg = /^[A-Za-z\s]+$/;
                                        const value = e.target.value;
                                        // const isValid = /^[A-Za-z\s]+$/.test(value);
                                        if (reg.test(value) || value === '') {
                                            setName(value);
                                        }
                                    }} />
                            </div>
                            <div className={style['inputbtns']} style={{ border: `1px solid ${highlightColor}` }}>
                                <input
                                    placeholder="Enter Card Number"
                                    value={cardNumber}
                                    type="text"
                                    style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor, height: '100%', width: '100%', border: 'none', background: 'transparent', fontSize: '14px' }}
                                    onChange={(e) => {
                                        handleCardNumberChange(e)
                                    }}
                                    maxLength={19}
                                />
                            </div>
                            <div className={style['inputbtns']} style={{ display: 'flex', justifyContent: 'space-between', padding: '0', boxShadow: 'none' }}>
                                <div className={style['inputbtns']} style={{ border: `1px solid ${highlightColor}`, width: '70%' }}>
                                    <input
                                        placeholder="Expiry Date"
                                        value={expiry}
                                        type="text"
                                        style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor, height: '100%', width: '70%', border: 'none', background: 'transparent', fontSize: '14px' }}
                                        onChange={(e) => {
                                            handleExpiryDateChange(e)
                                        }}
                                        maxLength={5}
                                    />
                                </div>
                                <div className={style['inputbtns']} style={{ border: `1px solid ${highlightColor}`, width: '27%' }}>
                                    <input
                                        placeholder="CVV"
                                        value={cvv}
                                        type="text"
                                        style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor, height: '100%', width: '80px', border: 'none', background: 'transparent', fontSize: '14px' }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const isValid = /^\d{0,3}$/.test(value);
                                            if (isValid) {
                                                setCvv(e.target.value);
                                            }
                                        }}
                                        maxLength={4}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={style['buttoncontainer']}>
                        <Button
                            disable={click || validExpiry}
                            color={data?.userProfileCustomization?.highlightColor || highlightColor}
                            fnc={()=>{addCard()}}
                            text="Update Card Details"
                            font={data?.userProfileCustomization?.fontStyle || fontstyle}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default EditCard;
