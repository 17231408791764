import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, json } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import Logout from './Components/Login/Logout';
import { newLoginSetting, addClientConfig, commanUrlValue } from '../src/Reducer/commonData';
import { useDispatch } from 'react-redux';
import Address from './Components/address/Address';
import { UserDataCollection } from './Components/NewLogin/userDataCollection';
import { ResetPassword } from './Components/NewLogin/resetPassword';
// import { NewSignUp } from './Components/NewLogin/signUp';
import { NewLogin } from './Components/NewLogin/login';
import { ForgotPassword } from './Components/NewLogin/forgetPassword';
import { SetPassword } from './Components/NewLogin/setPassword';
import { LoginEmail } from './Components/NewLogin/loginEmail';
import { NewVarifyOTP } from './Components/NewLogin/verifyOTP';
import Navigator from './Components/user/Navigator';
import { antdMessageError, antdMessageSuccess } from './Components/common/antdMessageComponent';
import { checkSuccessCode } from './utills/validator';
import AdBlocker from './Components/ad-blocker/ad-blocker';
import UserManagement from './Components/User-Management/UserManagement';
import { SecondaryDetails } from './Components/user/SecondaryDetails';

function App() {
  const dispatch = useDispatch();
  const [googleClientId, setGoogleClientId] = useState('');
  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        // Page is restored from bfcache
        // Perform any action needed like re-fetching data or forcing a re-render
        console.log('Page was restored from bfcache');
        // Example: force re-render
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);
  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const clientData = JSON.parse(localStorage.getItem('commonUrlData'));
      const clientId = queryParams.get('clientId') || clientData?.clientId || '';
      const data = await axios.get(`${process.env.REACT_APP_API_URL}/client/login-customization?clientId=${clientId}`);
      if (!checkSuccessCode(data)) {
        return antdMessageError(data?.data?.message || 'Failed to fetch data');
      }
      setGoogleClientId(data?.data?.loginCustomization?.googleAccountId);
      dispatch(
        addClientConfig({
          groupId: data.data.groupId,
          clientId,
          uiConfig: data.data.loginCustomization.uiConfig,
          physicalCountries: data.data.physicalCountries,
        }),
      );

      document.title = data.data.ssoDocumentTitle ? data.data.ssoDocumentTitle : 'SSO Login';
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = data.data.loginCustomization.brandLogoUrl;
      dispatch(newLoginSetting({ ...data?.data }));
      const urlData = {
        anonId: queryParams.get('anonId'),
        redirectUrl: queryParams.get('redirectUrl'),
        purchaseType: queryParams.get('purchaseType'),
        origin: queryParams.get('origin'),
        clientId: queryParams.get('clientId'),
        paywallId: queryParams.get('paywallId'),
        mobileView: queryParams.get('mobileView'),
        paywallType: queryParams.get('paywallType'),
        journeyId: queryParams.get('journeyId'),
        segmentId: queryParams.get('segmentId'),
        transactionId: queryParams.get('transactionId'),
        SHOWPOP: queryParams.get('SHOWPOP'),
        popUpId: queryParams.get('popUpId'),
        siteUrl: queryParams.get('siteUrl'),
        skip: queryParams.get('skip'),
      };
      localStorage.setItem('commonUrlData', JSON.stringify(urlData));
      dispatch(commanUrlValue({ ...urlData }));
    })();
  }, []);

  if (!googleClientId) return;
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Router>
        <Routes>
          {/* <Route path="/register" element={<NewSignUp />} /> */}
          <Route path="/login" element={<NewLogin />} />
          <Route path="/personalinfo" element={<UserDataCollection />} />
          <Route path="/setpassword" element={<SetPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/forgetpassword" element={<ForgotPassword screen={undefined} setscreen={undefined} />} />
          <Route path="/loginemail" element={<LoginEmail />} />
          <Route path="/newvarifyotp" element={<NewVarifyOTP />} />
          <Route path="/ad-blocker" element={<AdBlocker />} />
          <Route path="/user-management" element={<UserManagement />} />
          {/* //new Login stystem End */}

          <Route
            path="/address"
            element={
              <ProtectedRoute>
                <Address />
              </ProtectedRoute>
            }
          />
          <Route path="logout" element={<Logout />} />

          {/* after logged in  */}
          <Route path="/userdetails" element={<Navigator />} />

          <Route path="/secondarydetails" element={<SecondaryDetails />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
