import React from 'react';

const button = ({ text, color, fnc, bcolor, boxshadow, textcolor, font, disable }) => {
  const isDisabled = (disable === true || disable === "true");
  const buttonstyle = {
    background: color,
    margin: '10px auto',
    width: '90%',
    height: '44px',
    // color: '#E4E4E4',
    border: bcolor || 'none',
    borderRadius: '5px',
    cursor: isDisabled === 'true' ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? 0.6 : 1,
    fontSize: '14px',
    fontWeight: 'bolder',
    color: textcolor || 'white',
    boxShadow: boxshadow || 'none',
    fontFamily: font || 'Roboto',
    transition: 'all 0.3s ease',
  };
  return (
    <button disabled={isDisabled} style={buttonstyle} onClick={!isDisabled ? fnc : undefined} aria-disabled={isDisabled}>
      {text}{' '}
    </button>
  );
};

export default button;
