import { logOutHandlerForMobile } from '../../utills/validator';

export async function CSCGetAccessToken(refreshToken) {
  if (refreshToken) {
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/authorization/refresh`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    if (resp.status === 200) {
      const data = await resp.json();
      return data.accessToken;
    } else {
      logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
      window.parent.postMessage({ source: 'logout-from-user' }, '*');
      throw new Error('Error refreshing access token');
    }
  } else {
    throw new Error('Error user not logged in');
  }
}
export function CSCCheckJWTExpired(token) {
  if (!token) {
    return true;
  }
  const jwt = token.split('.');
  if (jwt.length !== 3) {
    return true;
  }
  const payload = JSON.parse(atob(jwt[1]));
  const now = new Date();
  return now.getTime() >= payload.exp * 1000;
}

export function getWordsFromDuration(duration, unit) {
  if (duration <= 0) return 'Invalid duration';
  if (unit === undefined || unit === null || !unit) {
    unit = 'months';
  }

  let result = '';

  switch (unit) {
    case 'days': {
      const years = Math.floor(duration / 365);
      const remainingDaysAfterYears = duration % 365;
      const months = Math.floor(remainingDaysAfterYears / 30);
      const days = remainingDaysAfterYears % 30;

      if (years) result += `${years} year${years > 1 ? 's' : ''}`;
      if (months) result += `${result ? ' & ' : ''}${months} month${months > 1 ? 's' : ''}`;
      if (days) result += `${result ? ' & ' : ''}${days} day${days > 1 ? 's' : ''}`;
      break;
    }

    case 'months': {
      const years = Math.floor(duration / 12);
      const months = duration % 12;

      if (years) result += `${years} year${years > 1 ? 's' : ''}`;
      if (months) result += `${result ? ' & ' : ''}${months} month${months > 1 ? 's' : ''}`;
      break;
    }

    case 'years': {
      result = `${duration} year${duration > 1 ? 's' : ''}`;
      break;
    }

    default:
      result = 'Invalid unit';
  }

  return result;
}

export function userProfileHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.userProfileHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect userProfilehandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.userProfileHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect userProfilehandler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('USER_PROFILE', value);
  } catch (error) {
    console.warn('did not find flutter 2 userProfilehandler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView userProfilehandler');
  }
}

// Function to validate email format
export function isValidEmail(email) {
  // if (typeof email !== 'string') return ''; // Ensure the input is a string
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) ? email : '';
}

// Function to validate number format
export function isValidNumber(number) {
  if (typeof number !== 'string') return ''; // Ensure the input is a string
  const numberRegex = /^[0-9]+$/; // Regex to match only digits
  return numberRegex.test(number) ? number : '';
}

export function removeDuplicateParams(url) {
  const [baseUrl, queryString] = url.split('?');
  const params = new URLSearchParams(queryString);
  const uniqueParams = new URLSearchParams();

  // Iterate through each parameter and add the first occurrence to uniqueParams
  for (const [key, value] of params) {
    if (!uniqueParams.has(key)) {
      uniqueParams.set(key, value);
    }
  }

  // Construct a new URL with unique parameters
  const newUrl = `${baseUrl}?${uniqueParams.toString()}`;
  console.log(newUrl, 'removeDuplicateParams');

  return newUrl;
}

export const URLshortner = (url) => {
  const data = fetch(`${process.env.REACT_APP_API_URL}/url-shortner`, {
    method: 'POST',
    body: JSON.stringify({
      url: url,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const redirectToDomain = (websiteDomain) => {
  const queryParams = new URLSearchParams(window.location.search);
  const mobileView = queryParams.get('mobileView') === 'true';
  if (websiteDomain && !mobileView) {
    window.location.href = websiteDomain;
  } else if (mobileView) {
    const value = JSON.stringify({ message: 'REDIRECT_TO_HOME_SCREEN' });
    redirectToHomeScreenHandlerForMobile(value);
  }
};

export function redirectToHomeScreenHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.redirectToHomeScreenHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler', value);
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.redirectToHomeScreenHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('REDIRECT_TO_HOME_SCREEN', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler', value);
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler', value);
  }
}
