import React, { useEffect, useState } from 'react';
import style from '../Style/Edituser.module.css';
import Arrow from './icons/back Vector';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import Button from './button';
import axios from 'axios';
import Tick from './icons/cardTick';
import Dots from './icons/threeDots';
import Add from './icons/addIcon';
import { antdMessageError, antdMessageSuccess } from '../../Components/common/antdMessageComponent';
import { checkSuccessCode, logOutHandlerForMobile, userProfileUpdateHandlerForMobile } from '../../utills/validator';
import AmericanExpressIcon from "./icons/American.svg";
import VisaCard from "./icons/Visa.svg";
import MasterCard from "./icons/Master.svg";
import DinerClubCard from "./icons/Diners.svg";
import DiscoverCard from "./icons/Discover.svg";
import JcbCard from "./icons/Jcb.svg";


const ManageCards = ({ setscreen, Data, rightvaluepassed, color }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(true);
    const [data, setdata] = useState(Data);
    const [rightvalue, setrightvalue] = useState();
    const [cardDetails, setCardDetails] = useState();
    const [showEditDeleteAndUdateMenu, setShowEditDeleteAndUdateMenu] = useState({
        show: false,
        id: "",
    });
    const textColor = 'red';
    const backgroundColor = '#f5f5ff';
    const highlightColor = '#7583ff';
    const inputfieldColor = '#e0e4ff';
    const fontstyle = 'poppins';

    const handleCardType = (type) => {
        if (type === 'VS') {
            return VisaCard;
        } else if (type === 'MS') {
            return MasterCard;
        } else if (type === 'AMEX') {
            return AmericanExpressIcon;
        } else if (type === 'DS') {
            return DiscoverCard;
        } else if (type === 'DINER') {
            return DinerClubCard;
        } else if (type === 'JCB') {
            return JcbCard;
        }
    }

    const clientConfig = JSON.parse(localStorage.getItem('commonUrlData'));
    const fetchData = async (token) => {
        if (token) {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
                    headers: { Authorization: `Bearer ${token}` },
                });
                setdata(response.data);
                setLoading(false);
            } catch (error) {
                if (error?.response?.status === 404) {
                    antdMessageError('It seems your account has been logged out. Please try logging in again');
                    window.parent.postMessage({ source: 'logout-from-user' }, '*');
                    logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
                }
            }
        }
    };
    const getCardDetail = async (clientId) => {
        setLoading(true)
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_URL}/cybersource/cardDetails?clientId=${clientId}`,
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                setCardDetails(response?.data?.cardDetails);
                setLoading(false);
            } catch (error) {
                if (error?.response?.status === 404) {
                    antdMessageError('It seems your account has been logged out. Please try logging in again');
                    window.parent.postMessage({ source: 'logout-from-user' }, '*');
                    logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
                    setLoading(false);
                }
            }
        }
    };

    const makeDefault = async (id) => {
        const accessToken = localStorage.getItem('accessToken');
         if (accessToken) {
             try {
                 const response = await axios({
                     method: 'PATCH',
                     url: `${process.env.REACT_APP_API_URL}/cybersource/make-card-default/${id}?clientId=${clientConfig?.clientId}`,
                     headers: { Authorization: `Bearer ${accessToken}` }
                 });
                 getCardDetail(clientConfig?.clientId);
             } catch (error) {
                 setLoading(false);
                 if (error?.response?.status !== 201) {
                     antdMessageError('Cannot complete the action.');
                 }
             }
         }
        setShowEditDeleteAndUdateMenu({
            id: '',
            show: false,
        })
    }

    const deleteCard = async (item) => {
        console.log(item);
        
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios({
                method: 'DELETE',
                url: `${process.env.REACT_APP_API_URL}/cybersource/remove-card?tokenId=${item?._id}`,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            getCardDetail(clientConfig?.clientId);
            antdMessageSuccess('Card Deleted Successfully.');
        } catch (error) {
            console.error('Error fetching card details:', error);
            throw error; 
        }
    };
    useEffect(() => {
        const refreshToken = queryParams.get('refreshToken');
        refreshToken &&
            (async () => {
                const accessToken = localStorage.getItem('accessToken');
                CSCCheckJWTExpired(accessToken);
                if (accessToken.length > 10 && !CSCCheckJWTExpired(accessToken)) {
                    fetchData(accessToken);
                    getCardDetail(clientConfig?.clientId);
                } else {
                    const accessToken = await CSCGetAccessToken(refreshToken);
                    localStorage.setItem('accessToken', accessToken);
                    fetchData(accessToken);
                    getCardDetail(clientConfig?.clientId);
                }
            })();
    }, []);

    useEffect(() => {
        if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
            setrightvalue(0);
        } else {
            setrightvalue();
        }
    }, [data, rightvalue]);
    const none = () => { };

    return (
        <>
            {loading ? (
                <div
                    className={style['outercontainer']}
                    style={{
                        background: color,
                        right: rightvaluepassed,
                    }}
                >
                    <div className={style['loader-container']}>
                        <div className={style['loader']}></div>
                    </div>
                </div>
            ) : (
                <div
                    className={style['outercontainer']}
                    style={{
                        right: rightvalue,
                        background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
                        fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
                        color: data?.userProfileCustomization?.primaryTextColor || textColor,
                    }}
                >
                    <div className={style['editable']}>
                        <div
                            className={style['head']}
                            style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
                        >
                            <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
                                <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                            </div>
                            <div className={style['topic']}>Manage Payments</div>
                        </div>
                        <div className={style['content']}>
                            <div className={style['cards-details']}>
                                <div style={{ fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle, color: data?.userProfileCustomization?.primaryTextColor || textColor, fontSize: '14px', fontWeight: '600' }}>Saved Cards</div>
                                {cardDetails?.length === 0 && 
                                <div className={style['noSaved']} style={{color:data?.userProfileCustomization?.primaryTextColor || textColor}}>You currently don't have any saved cards.</div>
                                }
                                {cardDetails?.map((e, index) => (
                                    <div className={style['card-container']}>
                                        <div className={style['card-component']} style={{ border: `1px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}`, color: data?.userProfileCustomization?.primaryTextColor || textColor, fontSize: '14px', backgroundColor: data?.userProfileCustomization?.inputfieldColor || inputfieldColor }}>
                                            <div style={{ padding: '0 7px', borderRight: `1px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}>****{e?.cardDetails?.number.substr(-4)}</div>
                                            <div style={{ padding: '0 7px' }}>{e?.nameOnCard || 'Unknown'}</div>

                                            <div className={style['tickmark']}>
                                                <div style={{ marginRight: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img
                                                    src={handleCardType(e?.cardDetails?.cardType)}
                                                    alt="AmericanExpressIcon"
                                                    className={style["card-img"]}
                                                /></div>
                                                {e?.isDefault ? <Tick fill={'#31b20f'} /> :
                                                    <div
                                                        style={{ cursor: 'pointer', display: 'flex' }}
                                                        onClick={() => {
                                                            setShowEditDeleteAndUdateMenu({
                                                                show: !showEditDeleteAndUdateMenu?.show,
                                                                id: e?._id,
                                                            })
                                                        }}>
                                                        <Dots fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                                                    </div>}


                                                {(showEditDeleteAndUdateMenu?.show && showEditDeleteAndUdateMenu?.id === e?._id) && (
                                                    <div className={style['popup_box']}>
                                                        <div onClick={() => { makeDefault(e?._id) }}> Set as Default</div>
                                                        <div style={{ color: 'red' }} onClick={() => { deleteCard(e) }}>Delete</div>
                                                    </div>)}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }} className={style['addCard']}>
                                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }} onClick={() => { setscreen('EditCard') }}>
                                        <Add fill={data?.userProfileCustomization?.primaryTextColor || textColor} />Add Card
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ManageCards;
