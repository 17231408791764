import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import style from '../Style/pwd.module.css';
import Arrow from './icons/back Vector';
import { ReactComponent as Eyeicon } from '../assets/loginsettings/eye_icon.svg';
import { ReactComponent as EyeCloseicon } from '../assets/loginsettings/eyeClose_icon.svg';
import { ReactComponent as Blank } from './icons/blank.svg';
import { ReactComponent as Green } from './icons/green.svg';
import { antdMessageError, antdMessageSuccess } from '../../Components/common/antdMessageComponent';
import Button from './button';
import axios from 'axios';
import { checkSuccessCode, logOutHandlerForMobile, userProfileUpdateHandlerForMobile } from '../../utills/validator';

const Pwdcreation = ({ setscreen, rightvaluepassed, color }) => {
  const [data, setdata] = useState();

  const textColor = '#00063d';
  const backgroundColor = '#f5f5ff';
  const highlightColor = '#7583ff';
  const inputfieldColor = '#e0e4ff';
  const fontstyle = 'poppins';
  const collor = data?.userProfileCustomization?.highlightColor || highlightColor;
  const navigate = useNavigate();
  const toggleData = useSelector((state) => state.loginSetting);
  const [password, setpassword] = useState(false);
  const [currentpassword, setcurrentpassword] = useState(null);
  const [newpassword, setnewpassword] = useState('');
  const [confirmpassword, setconfirmpassword] = useState('');
  const [logout, setLogout] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [validatedPassword, setvalidatedPassword] = useState(false);
  const [incorrect, setincorrect] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  // State to track focus
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);

  // Event handlers to update state
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleFocus1 = () => setIsFocused1(true);
  const handleBlur1 = () => setIsFocused1(false);
  const handleFocus2 = () => setIsFocused2(true);
  const handleBlur2 = () => setIsFocused2(false);

  const [authorised, setauthorised] = useState(false);

  // Conditional styling
  const divStyle = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused ? '0 0 0' : '0 0 2px',
  };
  const divStyle1 = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused1 ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused1 ? '0 0 0' : '0 0 2px',
  };
  const divStyle2 = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused2 ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused2 ? '0 0 0' : '0 0 2px',
  };

  //password verify field
  const [passwordConfig, setPasswordConfig] = useState({
    minLength: toggleData?.passwordConfig?.minLength,
    mixCase: toggleData?.passwordConfig?.MixCase ? false : true,
    alphaNumeric: toggleData?.passwordConfig?.alphaNumeric ? false : true,
    specialChar: toggleData?.passwordConfig?.specialChar ? false : true,
  });

  const handlePasswordChange = (newval) => {
    setnewpassword(newval);

    setPasswordConfig({
      minLength: newval.length >= toggleData?.passwordConfig?.minLength,
      mixCase: toggleData?.passwordConfig?.MixCase ? /[A-Z]/.test(newval) && /[a-z]/.test(newval) : true,
      alphaNumeric: toggleData?.passwordConfig?.alphaNumeric ? /[a-zA-Z]/.test(newval) && /\d/.test(newval) : true,
      specialChar: toggleData?.passwordConfig?.specialChar ? /[!@#$%^&*(),.?":{}|<>]/.test(newval) : true,
    });
  };
  useEffect(() => {
    handlePasswordChange('');
  }, []);

  const handleCheckboxChange = (event) => {
    setLogout(event.target.checked);
  };
  const [validated, setvalidated] = useState(false);

  useEffect(() => {
    if (newpassword.length >= toggleData?.passwordConfig?.minLength) {
      if (newpassword === confirmpassword) {
        setvalidated(true);
      } else {
        setvalidated(false);
      }
    } else {
      setvalidated(false);
    }
  }, [confirmpassword, newpassword, passwordConfig]);

  useEffect(() => {
    if (
      passwordConfig.minLength &&
      passwordConfig.mixCase &&
      passwordConfig.alphaNumeric &&
      passwordConfig.specialChar
    ) {
      setauthorised(true);
    } else {
      setauthorised(false);
    }
  }, [newpassword, passwordConfig]);

  const [rightvalue, setrightvalue] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);

  const fetchData = async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${token}` },
      });
      setdata(response.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError('It seems your account has been logged out. Please try logging in again');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    }
  };

  useEffect(() => {
    const refreshToken = queryParams.get('refreshToken');

    refreshToken &&
      (async () => {
        const accessToken = localStorage.getItem('accessToken');
        CSCCheckJWTExpired(accessToken);
        if (accessToken.length > 10 && !CSCCheckJWTExpired(accessToken)) {
          fetchData(accessToken);
        } else {
          const accessToken = await CSCGetAccessToken(refreshToken);
          localStorage.setItem('accessToken', accessToken);
          fetchData(accessToken);
        }
      })();
  }, []);

  const checkUserPwd = async () => {
    if (localStorage.getItem('accessToken') !== null) {
      try {
        const response = await axios({
          method: 'PATCH',
          url: `${process.env.REACT_APP_API_URL}/authorization/validate-password`,
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          data: { pwd: currentpassword },
        });
        if (response.status === 200) {
          setvalidatedPassword(true);
          setincorrect(false);
        } else {
          setincorrect(true);
        }
      } catch (error) {
        // antdMessageError("New password can't be the same as current password.");
        setincorrect(true);
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  const updatePwd = async () => {
    if (localStorage.getItem('accessToken') !== null) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/authorization/set-new-password`,
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          data: { password: confirmpassword, logoutAllSessions: logout },
        });
        if (!checkSuccessCode(response)) return;
        if (logout) {
          logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
          window.parent.postMessage({ source: 'logout-from-user' }, '*');
        } else {
          antdMessageSuccess('Updated Successfully');
          setscreen('userdetails');
        }
      } catch (error) {
        antdMessageError('Error While creating user password');
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  const changePwd = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        const response = await axios({
          method: 'PATCH',
          url: `${process.env.REACT_APP_API_URL}/authorization/change-password`,
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          data: { password: confirmpassword.trim(), logoutAllSessions: logout },
        });
        if (!checkSuccessCode(response)) return;
        if (logout) {
          logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
          window.parent.postMessage({ source: 'logout-from-user' }, '*');
        } else {
          antdMessageSuccess('Updated Successfully');
          userProfileUpdateHandlerForMobile(JSON.stringify({ message: 'USER_PASSWORD_UPDATE', statusCode: '200' }));
          setscreen('userdetails');
        }
      } catch (error) {
        antdMessageError(error.response.data.message);
        throw error;
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  useEffect(() => {
    setpassword(data?.hasPassword);
  }, [data]);

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue(null);
    }
  }, [data]);
  const forget = () => {
    setscreen('forgotpwd');
    document.getElementById('card-container').style.zIndex = '-100';
    // alert('kijuhg');
    // let newParams = navigate(`/forgetpassword?${queryParams.toString()}`);
  };
  const blankAction = () => {};

  return (
    <>
      {loading ? (
        <div
          className={style['outercontainer']}
          style={{
            background: color,
            right: rightvaluepassed,
          }}
        >
          <div className={style['loader-container']}>
            <div className={style['loader']}></div>
          </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvalue,
            background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
            fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
            color: data?.userProfileCustomization?.primaryTextColor || textColor,
          }}
        >
          <div className={style['editable']}>
            <div
              className={style['head']}
              style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
            >
              <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
                <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
              </div>
              <div className={style['topic']}>{password ? 'Change Password' : 'Create Password'}</div>
            </div>
            <div className={style['content']}>
              <div className={style['change']}>
                {password && (
                  <div className={style['currentpwd']}>
                    <div className={style['pwdtag']}>Your Current Password</div>
                    <div className={style['inputbtns']} style={divStyle}>
                      <input
                        className={style['inputtag']}
                        placeholder="Current Password"
                        style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                        type={passwordVisible ? 'text' : 'password'}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setcurrentpassword(e.target.value);
                        }}
                      />
                      <div onClick={togglePasswordVisibility}>{!passwordVisible ? <Eyeicon /> : <EyeCloseicon />}</div>
                    </div>
                    <hr style={{ border: '0px' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', zIndex: '22' }}>
                      {incorrect && <span style={{ color: 'red', fontSize: '12px' }}>Incorrect Password</span>}
                      <div
                        className={style['forgot']}
                        style={{
                          color: data?.userProfileCustomization?.highlightColor || highlightColor,
                          cursor: 'pointer',
                        }}
                        onClick={forget}
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </div>
                )}

                {(validatedPassword || !password) && (
                  <div className={style['newpwd']}>
                    <span className={style['pwdtag']}>Enter New Password</span>
                    <div className={style['inputbtns']} style={divStyle1}>
                      <input
                        className={style['inputtag']}
                        placeholder="Set Password"
                        style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                        type={passwordVisible1 ? 'text' : 'password'}
                        onFocus={handleFocus1}
                        onBlur={handleBlur1}
                        value={newpassword}
                        onChange={(e) => (validatedPassword || !password ? handlePasswordChange(e.target.value) : null)}
                      />
                      <div onClick={togglePasswordVisibility1}>
                        {!passwordVisible1 ? <Eyeicon /> : <EyeCloseicon />}
                      </div>
                    </div>
                    <hr style={{ border: '0px' }} />
                    <div className={style['must']}>
                      <div style={{ fontSize: '14px' }}>Password Should Contain the Following Parameters:</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!passwordConfig.minLength ? (
                          <Blank style={{ margin: '2px 5px' }} />
                        ) : (
                          <Green style={{ margin: '2px 5px' }} />
                        )}
                        Include Atleast {toggleData?.passwordConfig?.minLength} characters
                      </div>
                      {toggleData?.passwordConfig?.alphaNumeric && (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {!passwordConfig.alphaNumeric ? (
                            <Blank style={{ margin: '2px 5px' }} />
                          ) : (
                            <Green style={{ margin: '2px 5px' }} />
                          )}
                          Include Alpha-Numerical
                        </span>
                      )}
                      {toggleData?.passwordConfig?.MixCase && (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {!passwordConfig.mixCase ? (
                            <Blank style={{ margin: '2px 5px' }} />
                          ) : (
                            <Green style={{ margin: '2px 5px' }} />
                          )}
                          Include Mixed case
                        </span>
                      )}
                      {toggleData?.passwordConfig?.specialChar && (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {!passwordConfig.specialChar ? (
                            <Blank style={{ margin: '2px 5px' }} />
                          ) : (
                            <Green style={{ margin: '2px 5px' }} />
                          )}
                          Include Special characters (eg. !,$,&,@)
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(validatedPassword || !password) && (
                  <div className={style['confirmpwd']}>
                    <span className={style['pwdtag']}>Confirm Password</span>
                    <div className={style['inputbtns']} style={divStyle2}>
                      <input
                        className={style['inputtag']}
                        placeholder="Confirm Password"
                        style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                        type={passwordVisible2 ? 'text' : 'password'}
                        onFocus={handleFocus2}
                        onBlur={handleBlur2}
                        value={confirmpassword}
                        onChange={(e) => (validatedPassword || !password ? setconfirmpassword(e.target.value) : null)}
                      />
                      <div onClick={togglePasswordVisibility2}>
                        {!passwordVisible2 ? <Eyeicon /> : <EyeCloseicon />}
                      </div>
                    </div>
                    {/* {!authorised && <div style={{ fontSize: '13px', color: 'red' }}>* password does not match1</div>} */}
                    {authorised && !validated && (
                      <div style={{ fontSize: '13px', color: 'red' }}>
                        * The current password and confirmed password do not match
                      </div>
                    )}
                    <div className={style['check']}>
                      <label class={style['custom-checkbox']}>
                        <input type="checkbox" checked={logout} onChange={handleCheckboxChange} />
                        <span class={style['checkmark']}></span>
                        Log Out From All Devices
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className={`${style['buttoncontainer']} ${!validatedPassword ? '' : style['blur']}`} style={{position:'absolute',bottom:'10px'}}>
            {password && !validatedPassword ? (
              <Button
                type="submit"
                color={data?.userProfileCustomization?.highlightColor || highlightColor}
                fnc={checkUserPwd}
                text="Check Password"
                font={data?.userProfileCustomization?.fontStyle || fontstyle}
              />
            ) : (
              ''
            )}
          </div> */}

          <div
            className={`${style['buttoncontainer']} ${
              (validated && authorised) || !validatedPassword ? '' : style['blur']
            }`}
          >
            {password && !validatedPassword ? (
              <Button
                type="submit"
                color={data?.userProfileCustomization?.highlightColor || highlightColor}
                fnc={checkUserPwd}
                text="Check Password"
                font={data?.userProfileCustomization?.fontStyle || fontstyle}
              />
            ) : (
              ''
            )}
            {password && validatedPassword && (
              <Button
                type="submit"
                color={data?.userProfileCustomization?.highlightColor || highlightColor}
                fnc={validated && authorised ? changePwd : blankAction}
                text="Update Password"
                font={data?.userProfileCustomization?.fontStyle || fontstyle}
                disable={!(validated && authorised)}
              />
            )}
            {!password && (
              <Button
                type="submit"
                color={data?.userProfileCustomization?.highlightColor || highlightColor}
                fnc={validated && authorised ? updatePwd : blankAction}
                text="Create Password"
                font={data?.userProfileCustomization?.fontStyle || fontstyle}
                disable={!(validated && authorised)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Pwdcreation;
