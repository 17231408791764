import React from 'react';

const Calender = ({ fill }) => {
    return (
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 2C18.023 2 22.5 6.477 22.5 12C22.5 17.523 18.023 22 12.5 22C6.977 22 2.5 17.523 2.5 12C2.5 6.477 6.977 2 12.5 2ZM12.5 3.5C10.2457 3.5 8.08365 4.39553 6.48959 5.98959C4.89553 7.58365 4 9.74566 4 12C4 14.2543 4.89553 16.4163 6.48959 18.0104C8.08365 19.6045 10.2457 20.5 12.5 20.5C14.7543 20.5 16.9163 19.6045 18.5104 18.0104C20.1045 16.4163 21 14.2543 21 12C21 9.74566 20.1045 7.58365 18.5104 5.98959C16.9163 4.39553 14.7543 3.5 12.5 3.5ZM12.5 7C12.6989 7 12.8897 7.07902 13.0303 7.21967C13.171 7.36032 13.25 7.55109 13.25 7.75V11.25H16.75C16.9489 11.25 17.1397 11.329 17.2803 11.4697C17.421 11.6103 17.5 11.8011 17.5 12C17.5 12.1989 17.421 12.3897 17.2803 12.5303C17.1397 12.671 16.9489 12.75 16.75 12.75H13.25V16.25C13.25 16.4489 13.171 16.6397 13.0303 16.7803C12.8897 16.921 12.6989 17 12.5 17C12.3011 17 12.1103 16.921 11.9697 16.7803C11.829 16.6397 11.75 16.4489 11.75 16.25V12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H11.75V7.75C11.75 7.55109 11.829 7.36032 11.9697 7.21967C12.1103 7.07902 12.3011 7 12.5 7Z" fill={fill}/>
</svg>
);
};

export default Calender; 
