import axios from 'axios';
import { CSCCheckJWTExpired } from '../Components/user/utills';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const refreshToken = window.location.href.split('=')[2];
localStorage.setItem('refreshToken', refreshToken);

 async function CSCGetAccessToken(refreshToken) {
    if (refreshToken) {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/authorization/refresh`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      if (resp.status === 200) {
        const data = await resp.json();
        return data.accessToken;
      } else {
        // logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        // window.parent.postMessage({ source: 'logout-from-user' }, '*');
        throw new Error('Error refreshing access token');
      }
    } else {
      throw new Error('Error user not logged in');
    }
}
const api = axios.create({
  baseURL: API_BASE_URL,
});

const initializeAccessToken = async () => {
  try {
    const accessToken = await CSCGetAccessToken(refreshToken);
    localStorage.setItem('accessToken', accessToken);
    return accessToken;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem('accessToken');
    if (!token || CSCCheckJWTExpired(token)) {
      try {
        const newAccessToken = await CSCGetAccessToken(localStorage.getItem('refreshToken'));
        localStorage.setItem('accessToken', newAccessToken);
        token = newAccessToken;
      } catch (error) {
        console.error('Token refresh failed:', error);
      }
    }

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
initializeAccessToken().catch(console.error);

export const fetchUsers = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}/subscription/user-lincense`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getSubscription = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}/authorization/list-user-subscriptions`);
    return response.data.active;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const creatUser = async (userData) => {
  try {
    const response = await api.post(`${API_BASE_URL}/subscription/assign-license`, userData);
    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.patch(`${API_BASE_URL}/subscription/user/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`${API_BASE_URL}/subscription/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const getCardDetails = async (clientId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/cybersource/cardDetails?clientId=${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching card details:', error);
    throw error;
  }
};


