import React, { useEffect, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import style from '../../Ui/DropDown/commonAntdSelectedBox.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Profile_icon from '../assets/loginsettings/profile_icon.svg';
import Gender_icon from '../assets/loginsettings/gender_icon.svg';
import Calender_icon from '../assets/loginsettings/calender_icon.svg';
import Search_icon from '../assets/loginsettings/search_icon.svg';
import CustomDropDownForSelect from '../../Ui/DropDown/CustomDropDown';
import { ReactComponent as DropDownArrow } from '../assets/loginsettings/dropDownArrow.svg';
import { Country_flag_json } from '../common/country-flag-json';
import { DatePicker, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { checkSuccessCode, validateEmail, ValidateMobileNumber } from '../../utills/validator';
import { useNavigate, useLocation } from 'react-router-dom';
import { setRegisterDetails } from '../../Reducer/commonData';
import dayjs from 'dayjs';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
import { CSCSendEvent, eventLocation, eventType } from '../../analytics';
import axios from 'axios';
import CTALoader from '../../utills/ctaLoader';
import { Footer } from './Footer';
import clsx from 'clsx';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import BackArrow from './BackArrow';
import { redirectToDomain } from '../user/utills';

export function UserDataCollection() {
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector((state) => state.newClientConfig.userDataCollection);
  const toggleData = useSelector((state) => state.loginSetting);
  const userDetails = useSelector((state) => state.userDetails);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const websiteDomain = useSelector((state) => state.websiteDomain);
  const [inputValue, SetInputValue] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const overridePageSettings =
    queryParams.get('secondaryDetails') === 'true' || commanUrlValue?.clientId === '673454977d7bef5f987dfaf2' || false;

  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase()),
  );
  const dropdownRender = (menu) => (
    <div>
      <div className={loginStyle['dropDown-search']}>
        <Input placeholder="Search..." onChange={(e) => SetInputValue(e.target.value)} />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );

  const checkinput = (value) => {
    if (userDetails?.loginType === 'PHONE' && validateEmail(value.toLowerCase())) {
      return dispatch(setRegisterDetails({ ...userDetails, secondaryEmail: value.toLowerCase() }));
    }
    if (userDetails?.loginType === 'EMAIL') {
      return dispatch(setRegisterDetails({ ...userDetails, secondaryPhone: value }));
    } else {
      dispatch(setRegisterDetails({ ...userDetails, secondaryEmail: value.toLowerCase() }));
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/register`,
        data: {
          ...(userDetails?.secondaryPhone && { secondaryPhone: userDetails?.secondaryPhone, countryCode: '+91' }),
          ...(userDetails?.secondaryEmail && { secondaryEmail: userDetails?.secondaryEmail }),
          ...(userDetails?.phone && { phone: userDetails?.phone, countryCode: '+91' }),
          ...(userDetails?.email && { email: userDetails?.email }),
          clientGroupId: clientGroupId,
          primary: userDetails?.primary,
          clientId: commanUrlValue.clientId,
          loginType: 'otp',
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
          gender: userDetails.gender,
          dateOfBirth: userDetails.dateOfBirth,
          name: userDetails.fullName,
          signUpOriginUrl: redirectUrl,
        },
      });
      if (checkSuccessCode(response)) {
        setLoading(false);
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/login', email: userDetails?.email, phone: userDetails?.phone },
        });
      }
    } catch (error) {
      setLoading(false);
      return antdMessageError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    (async () => {
      const detectCountry = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/detect-country`,
      });
      if (checkSuccessCode(detectCountry)) {
        const value = Country_flag_json?.find((e) => e?.code === detectCountry?.data?.countryCode);
        localStorage.setItem('dail_code', value?.dail_code);
        dispatch(setRegisterDetails({ ...userDetails, dail_code: value?.dail_code }));
      }
    })();
  }, []);

  useEffect(() => {
    const ssoSignupSecondaryDetailsViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_SIGNUP_SECONDARY_DETAILS,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoSignupSecondaryDetailsViewObject);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/login?${queryParams.toString()}`);
    }
  }, [navigate]);

  const ssoSignupClickObject = {
    eventType: eventType.CLICK,
    eventLocation: eventLocation.SSO_SIGNUP,
    clickAction: userDetails?.loginType,
    clientId: commanUrlValue?.clientId,
    anonId: commanUrlValue?.anonId,
    paywallId: commanUrlValue?.paywallId,
    url: decodeURIComponent(window.location.href || ''),
    segmentId: commanUrlValue?.segmentId,
    journeyId: commanUrlValue?.journeyId,
  };
  useEffect(() => {
    if (overridePageSettings) {
      return setBtnDisabled(false);
    }
    if (toggleData?.secondaryFields?.enabled) {
      if (
        toggleData?.secondaryFields?.enableSecondaryInput?.value === 'MANDATORY' &&
        userDetails.loginType === 'EMAIL' &&
        (!validateEmail(userDetails?.email) || userDetails?.secondaryPhone === '')
      ) {
        return setBtnDisabled(true);
      } else if (
        toggleData?.secondaryFields?.enableSecondaryInput?.value === 'MANDATORY' &&
        userDetails.loginType === 'PHONE' &&
        (!ValidateMobileNumber(userDetails?.phone) || userDetails?.secondaryEmail === '')
      ) {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.enableNameInput?.value === 'MANDATORY' && userDetails?.fullName === '') {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.dob?.value === 'MANDATORY' && userDetails?.dateOfBirth === '') {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.gender?.value === 'MANDATORY' && userDetails?.gender === '') {
        return setBtnDisabled(true);
      } else {
        return setBtnDisabled(false);
      }

      // if(toggleData?.secondaryFields?.enableSecondaryInput?.value === 'MANDATORY' && ValidateMobileNumber(userDetails.phone) && validateEmail(userDetails.secondaryEmail)){
      //   setBtnDisabled(false);

      // }
    } else {
      return setBtnDisabled(false);
    }
  }, [userDetails]);

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        if (toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password) {
          return;
        } else if (toggleData?.accessMethods?.password) {
          if (userDetails?.email !== '' && !validateEmail(userDetails?.email)) {
            return antdMessageWarning('Please enter a valid email address.');
          }
          CSCSendEvent(ssoSignupClickObject);
          navigate(`/setPassword?${queryParams.toString()}`);
        } else {
          if (userDetails?.email !== '' && !validateEmail(userDetails?.email)) {
            return antdMessageWarning('Please enter a valid email address.');
          }
          navigate(`/newvarifyotp?${queryParams.toString()}`, {
            state: { backRoute: '/personalinfo', email: userDetails?.email, phone: userDetails?.phone },
          });
        }
      }
    }
  };

  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('email');
    queryParams.delete('phone');

    navigate(`/login?${queryParams.toString()}`);
  };

  return (
    <div className={loginStyle['main-container']}>
      <div style={{ flexGrow: 1 }}>
        <div className={loginStyle['header-logo']}>
          <img
            onClick={() => redirectToDomain(websiteDomain)}
            className={loginStyle['image-logo']}
            src={toggleData?.brandLogoUrl || Demo_image}
            alt="Demo_image"
          />
        </div>
        <div className={loginStyle['container']}>
          <div className={loginStyle['child-container']}>
            <div className={loginStyle['backArrow_icon']}>
              <BackArrow color={data?.formTitle?.color} handleClick={handleBackClick} />
            </div>
            <div className={loginStyle['heading-container']}>
              <div>
                <div className={loginStyle['heading-text']} style={data?.formTitle}>
                  {data?.formTitle?.text || 'Create new account'}
                </div>
                <div className={loginStyle['sub-text']} style={data?.subtitle}>
                  {data?.subtitle?.text || 'Please enter your valid details below'}
                </div>
              </div>
            </div>
            <div className={loginStyle['form-container']}>
              {userDetails?.loginType === 'PHONE' && toggleData?.loginMethods?.enableMobileLogin && (
                <>
                  <div className={loginStyle['input-container-common']}>
                    <img src={Mobile_icon} alt="Mobile_icon" />
                    <Input
                      placeholder="+91435647545345"
                      value={`+91${userDetails?.phone}`}
                      disabled={userDetails?.phone ? true : false}
                      style={{
                        backgroundColor: userDetails?.phone ? '#EBEBEB' : '',
                      }}
                    />
                  </div>
                  {toggleData?.secondaryFields?.enabled &&
                    toggleData?.secondaryFields?.enableSecondaryInput?.isRequired &&
                    !overridePageSettings && (
                      <div className={loginStyle['input-container-common']}>
                        <img src={Email_icon} alt="Email_icon" />
                        <Input
                          placeholder={data?.secondaryLoginInput?.text || 'Email123'}
                          value={userDetails?.email || userDetails?.secondaryEmail}
                          style={{ ...data?.secondaryLoginInput }}
                          onChange={(e) => checkinput(e.target.value)}
                          onKeyDown={keyDownHandler}
                        />
                      </div>
                    )}
                </>
              )}
              {userDetails?.loginType === 'EMAIL' && toggleData?.loginMethods?.enableEmailLogin && (
                <>
                  <div className={loginStyle['input-container-common']}>
                    <img src={Email_icon} alt="Email_icon" />
                    <Input
                      placeholder={data?.secondaryLoginInput?.text || 'Emajhjhujil'}
                      value={userDetails?.email || userDetails?.secondaryEmail}
                      disabled={userDetails?.email ? true : false}
                      style={{
                        ...data?.secondaryLoginInput,
                        backgroundColor: userDetails?.email ? '#EBEBEB' : '',
                      }}
                    />
                  </div>
                  {toggleData?.secondaryFields?.enabled &&
                    toggleData?.secondaryFields?.enableSecondaryInput?.isRequired &&
                    !overridePageSettings && (
                      <div className={loginStyle['input-container']}>
                        <img src={Mobile_icon} alt="Mobile_icon" />
                        <div className={loginStyle['dropDown-container']}>
                          <Select
                            dropdownRender={dropdownRender}
                            popupMatchSelectWidth={250}
                            filterOption={false}
                            suffixIcon={<DropDownArrow />}
                            className={style['common-select-box']}
                            popupClassName={style['common-dropdown-select']}
                            value={userDetails.dail_code}
                            style={{
                              fontFamily: 'Montserrat',
                              fontSize: '14px',
                            }}
                            onChange={(value) =>
                              dispatch(
                                setRegisterDetails({
                                  ...userDetails,
                                  dail_code: value,
                                }),
                              )
                            }
                            optionLabelProp="label"
                            onKeyDown={keyDownHandler}
                          >
                            {filteredOptions.map((option, index) => (
                              <Select.Option
                                key={index}
                                value={option.dail_code}
                                label={option.dail_code}
                                className={style['dropdown-options']}
                              >
                                <div className={loginStyle['dropdown-flag']}>
                                  <div className={loginStyle['flag-code']} style={{ fontSize: '12px' }}>
                                    {option.dail_code}
                                  </div>
                                  <div className={loginStyle['flag-name']}>{option.name}</div>
                                  <div className={loginStyle['flag']}>{option.flag}</div>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                        <div className={loginStyle['vertical-line']}></div>
                        <Input
                          placeholder={data?.secondaryLoginInput?.text || 'Phone Number'}
                          style={data?.secondaryLoginInput}
                          value={userDetails?.phone || userDetails?.secondaryPhone}
                          onChange={(e) => {
                            const reg = /^-?\d{1,15}$/;
                            if (reg.test(e.target.value) || e.target.value === '') {
                              checkinput(e.target.value);
                            }
                          }}
                          onKeyDown={keyDownHandler}
                        />
                      </div>
                    )}
                </>
              )}
              {toggleData?.secondaryFields?.enabled &&
                toggleData?.secondaryFields?.enableNameInput?.isRequired &&
                !overridePageSettings && (
                  <div className={loginStyle['input-container-common']}>
                    <img src={Profile_icon} alt="Profile_icon" />
                    <Input
                      placeholder={data?.fullName?.text || 'Full Name'}
                      style={data?.fullName}
                      onChange={(e) =>
                        dispatch(
                          setRegisterDetails({
                            ...userDetails,
                            fullName: e.target.value,
                          }),
                        )
                      }
                      value={userDetails?.fullName}
                      onKeyDown={keyDownHandler}
                    />
                  </div>
                )}
              {toggleData?.secondaryFields?.enabled &&
                toggleData?.secondaryFields?.dob?.isRequired &&
                !overridePageSettings && (
                  <div className={loginStyle['input-container-common']}>
                    <img src={Calender_icon} alt="Calender_icon" />
                    <DatePicker
                      allowClear={false}
                      style={{width:'100%'}}
                      suffixIcon={false}
                      placeholder={data?.dateOfBirth?.text || 'Date of Birth'}
                      inputReadOnly={true}
                      onChange={(e) => {
                        if (e) {
                          const utcDate = new Date(
                            Date.UTC(e.year(), e.month(), e.date(), e.hour(), e.minute(), e.second(), e.millisecond()),
                          ).toISOString();
                          dispatch(
                            setRegisterDetails({
                              ...userDetails,
                              dateOfBirth: utcDate,
                            }),
                          );
                        }
                      }}
                      onKeyDown={keyDownHandler}
                      value={userDetails?.dateOfBirth ? dayjs(userDetails?.dateOfBirth) : ''}
                      disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                    />
                  </div>
                )}
              {toggleData?.secondaryFields?.enabled &&
                toggleData?.secondaryFields?.gender?.isRequired &&
                !overridePageSettings && (
                  <div className={loginStyle['dropdown-container']}>
                    <img src={Gender_icon} alt="Gender_icon" />
                    <CustomDropDownForSelect
                      placeholder={data?.gender?.text || 'Gender'}
                      options={{ array: ['MALE', 'FEMALE', 'OTHERS'] }}
                      customStyleForOptions={{ fontFamily: 'Montserrat' }}
                      customStyle={{
                        border: '1px solid #6D6D6D',
                        fontFamily: 'Montserrat',
                        height: '48px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                      dropdownStyle={{ zIndex: 1 }}
                      hoverColor="#6D6D6D"
                      onKeyDown={keyDownHandler}
                      defaultValue={userDetails?.gender || undefined}
                      onChangeForSelect={(e) => dispatch(setRegisterDetails({ ...userDetails, gender: e }))}
                    />
                  </div>
                )}
            </div>
            {toggleData?.accessMethods?.password && userDetails.loginType !== 'PHONE' && (
              <button
                className={loginStyle['btn-style']}
                style={{
                  ...data?.signupViaPassword,
                  opacity: btnDisabled ? '0.5' : '1',
                  cursor: btnDisabled ? 'not-allowed' : 'pointer',
                }}
                disabled={btnDisabled}
                onClick={() => {
                  if (userDetails?.email !== '' && !validateEmail(userDetails?.email)) {
                    return antdMessageWarning('Please enter a valid email address.');
                  }
                  if (
                    (userDetails?.phone !== '' &&
                      !parsePhoneNumberFromString(userDetails?.dail_code + userDetails?.phone)?.isValid()) ||
                    (userDetails?.secondaryPhone !== '' &&
                      !parsePhoneNumberFromString(userDetails?.dail_code + userDetails?.secondaryPhone)?.isValid())
                  ) {
                    return antdMessageWarning('Please enter a valid number.');
                  }

                  CSCSendEvent(ssoSignupClickObject);
                  navigate(`/setPassword?${queryParams.toString()}`, { state: '/personalinfo' });
                }}
              >
                {data?.signupViaPassword?.text || 'Continue with Password'}
              </button>
            )}
            {toggleData?.accessMethods?.otp && (
              <div className={loginStyle['btn-margin']}>
                <button
                  className={clsx(loginStyle['btn-style'], loginStyle['otp-btn'])}
                  style={{
                    ...data?.buttonText,
                    opacity: btnDisabled ? '0.5' : '1',
                    cursor: btnDisabled ? 'not-allowed' : 'pointer',
                  }}
                  disabled={btnDisabled || loading}
                  onClick={() => {
                    if (userDetails?.secondaryEmail !== '' && !validateEmail(userDetails?.secondaryEmail)) {
                      return antdMessageWarning('Enter a Valid Email ID.');
                    }
                    handleRegister();
                  }}
                >
                  {loading ? (
                    <CTALoader color={'#000000'} />
                  ) : (
                    data?.buttonText?.text || 'Continue with One-time Passcode (OTP)'
                  )}
                </button>
              </div>
            )}
            <div className={loginStyle['by-continue']} style={data?.TandCtext}>
              {data?.TandCtext?.text || 'By continuing, you agree to our'}{' '}
              <a className={loginStyle['by-noHover']} href={toggleData?.redirects?.tnc} rel="noopener noreferrer">
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                className={loginStyle['by-noHover']}
                href={toggleData?.redirects?.privPolicy}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer termsAndConditions={toggleData?.redirects?.tnc} privacyAndPolicy={toggleData?.redirects?.privPolicy} />
    </div>
  );
}
