import React from 'react';

const Cards = ({ fill }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 10.4462V19.5538C23 19.9579 22.8526 20.3 22.5579 20.58C22.2632 20.86 21.9031 21 21.4777 21H8.52232C8.09691 21 7.73684 20.86 7.44211 20.58C7.14737 20.3 7 19.9579 7 19.5538V10.4462C7 10.0421 7.14737 9.7 7.44211 9.42C7.73684 9.14 8.09691 9 8.52232 9H21.4777C21.9031 9 22.2632 9.14 22.5579 9.42C22.8526 9.7 23 10.0421 23 10.4462ZM8.26316 12.1232H21.7368V10.4462C21.7368 10.3846 21.7098 10.3282 21.6558 10.277C21.6019 10.2257 21.5425 10.2 21.4777 10.2H8.52232C8.45747 10.2 8.39811 10.2257 8.34421 10.277C8.29018 10.3282 8.26316 10.3846 8.26316 10.4462V12.1232ZM8.26316 14.6768V19.5538C8.26316 19.6154 8.29018 19.6718 8.34421 19.723C8.39811 19.7743 8.45747 19.8 8.52232 19.8H21.4777C21.5425 19.8 21.6019 19.7743 21.6558 19.723C21.7098 19.6718 21.7368 19.6154 21.7368 19.5538V14.6768H8.26316Z" fill={fill}/>
</svg>

    );
};

export default Cards; 
